import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
      },
      theme: {
        themes: {
          light: {
            primary: '#061AB1',
            secondary: '#FFD100',
            accent: '#172154',
            error: '#8C0000',
            info: '#172154',
            success: '#73AF41',
            warning: '#FFC107',
            //kotta: '#061AB1'
          },
        },
      }
    })
    