import axios from 'axios'
const { tDocAPI } = require('@/config/config')
const apiClient = axios.create({
  baseURL: `${tDocAPI.url}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*"
  }
})

export const ApiService = {
  async postFiles(files, userId, cvr, frightForwarder){
    var formData = new FormData();
    console.log("files.length", files.length)

    for( var i = 0; i < files.length; i++ ){
      let file = files[i];
      console.log("foreach1", file)

      if(file.file != null){
        
        file.file.forEach((value) => {
          formData.append('files[' + i + ']', value);          
          console.log("foreach2")
        });
      }

      formData.append('files[' + i + ']', file.invoice);
      formData.append('receiver[' + i + ']', file.receiver);
      formData.append('country[' + i + ']', file.country);
      formData.append('consignmentid[' + i + ']', file.consignmentId == null? "":file.consignmentId);
      formData.append('sender[' + i + ']', file.sender == null? "" : file.sender);

      console.log("foreach3")
    }
  formData.append('userId', userId);
  formData.append('cvr', cvr)
  formData.append('frightForwarder', frightForwarder == null? "" : frightForwarder)

//  await this.setHeader()
 const token = localStorage.getItem('token')
  const {data} = await apiClient.post('Data/upload_filev2', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
    })
console.log(data,"servicedata")
    return data;
  },
  async Login(login){
    const {data} = await apiClient.post('Authentication/authenticate', login)
    return data;
  },
  async getData(){
    const {data} = await apiClient.get('Data/getCountries')
    return data;
  },
  async getAdditionalSender(){
    var customerId = localStorage.getItem("customerId")
    const {data} = await apiClient.get(`Data/getSender/${customerId}`)
    return data;
  },
  async setHeader() {
    const token = localStorage.getItem('token') || ''
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + token.toString()
  }
}