<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      class="nav-bar"
    >
      <div class="d-flex align-center" style="justify-self:center">
        <v-img
          alt="Vuetify Logo"
          class="shrink ml-15 mt-6"
          contain
          src="../src/assets/glsWhiteLogo.png"
          transition="scale-transition"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <ToldData/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Footer from '@/components/Footer';
import ToldData from './components/ToldData';

export default {
  name: 'App',

  components: {
    ToldData,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>

<style>
 .v-btn{
    border-radius:28px!important;
 } 
 .nav-bar{
    height: 90px !important;
 } 
</style>
