<template>
  <v-footer
      padless
      color="primary"
  >
    <v-col cols="12" sm="12">
        <v-card
            flat
            class="text-right"
            color="primary"
        >
          <v-col cols="12" sm="12">
            <v-card-text class="pt-8 white--text">
              <h3 class="text-bold">
                <h4>GLS Denmark</h4>
              </h3>
              <b-collapse id="collapse-4">
                <div>
                  <p>
                    Kokmose 3, 6000 Kolding <br/>
                    CVR 10 54 97 44
                    <br/>Telefon nr. 76 33 11 11 <br/>
                  </p>
                </div>
              </b-collapse>
            </v-card-text>
          </v-col>
          <v-divider></v-divider>
        </v-card>
    </v-col>

  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
</style>