<template>
  <v-container class="light lighten-2 mt-10">
    <div>
      <v-col cols="12" sm="12">
        <v-row align="center" justify="center">
          <h2 class="text-center mx-8 my-4">
            Upload dine Tolddata her<br />Nemt. Hurtigt. Nemt.
          </h2>
          <v-img
            max-height="65"
            max-width="65"
            src="../assets/uploadIconV2.png"
          ></v-img>
        </v-row>
      </v-col>

      <!-- <div><a download="CsvFormat" href="../assets/CsvFormat.csv">Download</a></div> -->

      <v-card class="ma-10 pt-15 px-15">
        <v-row>
          <v-col class="text-right" cols="12">
            <v-btn @click="resetInfo" class="ma-1" plain> Ryd Alt </v-btn>
          </v-col>
          <v-col cols="12" sm=12>
          <p>
            Kun felter med * er obligatoriske
          </p>
          </v-col>
           <v-col cols="12" :md="showSender?6:6" v-show="showSender">
            <v-text-field
              label="Afsender* - specielle tegn godtages ikke"
              maxlength="25"
              counter="25"
              v-model="sender"
              id="senderId"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :md="showSender?6:4" >
            <v-text-field
              label="Modtagernavn* - specielle tegn godtages ikke"
              maxlength="50"
              counter="50"
              v-model="receiver"
              id="receiverId"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :md="showSender?6:4" >
            <v-select
              label="Modtagerland*"
              :items="countries"
              v-model="chosenCountry"
            ></v-select>
          </v-col>
          <v-col cols="12" :md="showSender?6:4" >
            <v-text-field
              v-model="consignmentId"
              maxlength="11"
              counter="11"
              label="Forsendelsesnummer (det mindste pakkenummer - maks. 11 cifre)"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="6">
            <v-file-input
              label="Faktura*"
              outlined
              color="#primary"
              truncate-length="25"
              accept=".pdf"
              show-size
              v-model="chosenInvoiceFile"
            ></v-file-input>
          </v-col>
          <v-col cols="12" sm="6">
            <v-file-input
              multiple
              outlined
              color="primary"
              label="Upload data (kun xml og csv)"
              truncate-length="25"
              accept=".xml, .CSV"
              show-size
              v-model="chosenFile"
              hint="Ikke obligatorisk - tilføjes kun hvis korrekt varelinje format"
              persistent-hint
              
            ></v-file-input>
          </v-col>
        </v-row>

        <div class="text-right">
          <v-btn
            color="secondary"
            :disabled=" !showSender? !chosenInvoiceFile || !chosenCountry || !receiver : !chosenInvoiceFile || !chosenCountry || !receiver || !sender
            "
            class="my-4 black--text"
            cols="12"
            @click="addFile"
          >
            Tilføj dokument
          </v-btn>
        </div>

        <v-data-table
          v-show="dataAndFiles.length > 0"
          dense
          :headers="computedHeaders"
          :items="dataAndFiles"
          :footer-props="{
            'items-per-page-text': 'Rækker per side',
          }"
          item-key="name"
          class="elevation-1 mt-4"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Uploadede dokumenter</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>

              <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                  <v-card-title class="headline py-8"
                    >Er du sikker på du vil slette dette element?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12" class="text-right">
                      <v-btn
                        text
                        type="submit"
                        class="grey--text text-right mx-4"
                        @click="closeDelete"
                        >Fortryd
                      </v-btn>
                      <v-btn
                        type="submit"
                        color="primary"
                        class="white--text text-right"
                        @click="deleteItemConfirm"
                        >OK
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <div class="text-right"></div>
        <div class="text-right">
          <v-btn
            color="secondary"
            class="my-8 text-right black--text"
            dark
            @click="UploadData"
            :disabled="dataAndFiles.length == 0"
          >
            Indsend Data
          </v-btn>
        </div>
      </v-card>

      <v-dialog v-model="dialogLogin" persistent max-width="600px">
        <v-card class="pa-4">
          <v-card-title>
            <span class="headline">Verifikation</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="!logInDataReceived">
                <v-col cols="12">
                  <v-text-field
                    label="GLS Kundenummer*"
                    v-model="userId"
                    required
                  >
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> mdi-information-outline </v-icon>
                        </template>
                        Dit kundenummer til GLS (20800xxxxx)
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="CVR Nummer*"
                    v-model="cvr"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <div v-if="showLogInWarning">
                <v-alert type="warning">{{ errorMessageLogin }}</v-alert>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="!logInDataReceived" class="mr-4">
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="black--text" @click="Login">
              Log ind
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- DIALOG FOR RESPONSE MESSAGES FOR POST FILES -->
      <v-dialog v-model="dialogDataSender" persistent max-width="600px">
        <v-card class="pa-4">
          <v-card-text>
            <v-container>
              <div class="text-center" v-if="showFileDataSpinner">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <p class="mt-5">Vent venligst - sender data...</p>
              </div>
              <div v-if="fileDataReceived">
                <v-alert type="success">Dataen er modtaget</v-alert>
              </div>
              <div v-if="showFileDataWarning">
                <v-alert type="warning">{{ errorMessageUpload }}</v-alert>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="seconday"
              class="black--text"
              text
              @click="dialogDataSender = false"
              :disabled="showFileDataSpinner == true"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { ApiService } from "../services/ApiService";

export default {
  async created() {
    this.dialogLogin = true;
    await this.loadData();
    //this.getTest();
  },
  data() {
    return {
      frightForwarder: null,
      showSender: false,
      dialogLogin: true,
      dialogDelete: false,
      dialogDataSender: false,
      editedIndex: -1,
      showSpinner: false,
      logInDataReceived: false,
      showLogInWarning: false,
      errorMessageLogin: "",
      errorMessageUpload: "",
      sendingFileData: false,
      showFileDataSpinner: false,
      showFileDataWarning: true,
      fileDataReceived: false,
      //showErrorInput: true
      userId: "",
      cvr: "",

      chosenInvoiceFile: null,
      chosenFile: null, // <- initialize the v-model prop
      dataAndFiles: [],
      countries: [],
      chosenCountry: null,
      consignmentId: null,
      receiver: null,
      sender: null,
      headers: [
        {text: "Afsender",align: "start",sortable: true, value: "sender",show:false },
        {text: "Modtager",align: "start",sortable: true, value: "receiver",},
        { text: "Modtagerland", value: "country" },
        { text: "Filer", value: "file.length" },
        { text: "Faktura", value: "invoice.name" },
        { text: "ForsendelsesNr", value:"consignmentId"},
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    receiver(newVal) {
      if (this.receiver != null) {
        console.log("watcher works");
        let re = /[*'"/#,%&_$¤€?|]/g;
        console.log(newVal.replace(re, ""), "newVal");
        this.receiver = newVal.replace(re, "");
        //this.$set(this, 'receiverId', newVal.replace(re, ''));
        console.log();
        document.getElementById("receiverId").value = this.receiver;
      }
    },
  },
  computed: {
    computedHeaders () {
      if(this.showSender)
        return this.headers
      else
      return this.headers.filter(x => x.text !='Afsender')  
   }
  },
  methods: {
    async getAdditionalSender(){
      var x = await ApiService.getAdditionalSender();
      console.log("additional sender", x)
      if(x){
        console.log("x is something")
        this.showSender = true
        this.frightForwarder = x.customerName
      }
    },
    async loadData() {
      console.log("test");
      const countriesTemp = await ApiService.getData();
      console.log(countriesTemp);
      this.countries = countriesTemp;
    },
    async Login() {
      this.showLogInWarning = false;
      const loginResponse = await ApiService.Login({
        customerId: this.userId,
        cvr: this.cvr,
      }).catch();
      if (loginResponse.statusDescription) {
        this.errorMessageLogin = loginResponse.statusDescription;
        this.showLogInWarning = true;
      }
      if (loginResponse.token) {
        localStorage.setItem("customerId", loginResponse.customerId);
        localStorage.setItem("cvr", loginResponse.cvr);
        localStorage.setItem("token", loginResponse.token);
        this.dialogLogin = false;
        await this.getAdditionalSender()
      }
    },
    async UploadData() {
      this.showFileDataSpinner = true;
      this.showFileDataWarning = false;
      this.errorMessageUpload = "";
      this.dialogDataSender = true;
      this.fileDataReceived = false;

      const data = await ApiService.postFiles(
        this.dataAndFiles,
        localStorage.getItem("customerId"),
        localStorage.getItem("cvr"),
        this.frightForwarder
      )
        .then(() => {
          this.fileDataReceived = true;
          this.showFileDataSpinner = false;
          this.dataAndFiles = [];
        })
        .catch(this.handleUploadErrors);

      console.log("UploadData", data);
    },
    handleUploadErrors(err) {
      console.log(err)
      this.errorMessageUpload = err.response.status != 500 ? err.response.data: "Noget gik galt, prøv igen eller kontakt GLS på: 76 33 12 79";
      this.fileDataReceived = false;
      this.showFileDataSpinner = false;
      this.showFileDataWarning = true;
    },
    addFile() {
      if (this.receiver != null && this.chosenCountry != null && this.chosenInvoiceFile != null){
        if(this.showSender && this.sender && this.sender.length > 0)
        this.pushToArrayData();
        else if(this.showSender == false)
        this.pushToArrayData();
      }
      
    },
    pushToArrayData(){
        this.dataAndFiles.push({
          invoice: this.chosenInvoiceFile,
          file: this.chosenFile,
          receiver: this.receiver,
          country: this.chosenCountry,
          consignmentId: this.consignmentId,
          sender: this.sender
        });
        console.log(this.dataAndFiles, "pushToArrayData");
        this.resetInfo();
    },
    resetInfo() {
      this.chosenFile = null;
      this.receiver = null;
      this.chosenCountry = null;
      this.consignmentId = null;
      this.chosenInvoiceFile = null;
      this.sender = null;
    },

    deleteItem(item) {
      this.editedIndex = this.dataAndFiles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.dataAndFiles.indexOf(item);
      this.receiver = item.receiver;
      this.chosenInvoiceFile = item.invoice;
      this.chosenCountry = this.countries.selected;
      if (item.sender && item.sender.length > 0)
        this.sender = item.sender
      if (item.consignmentId && item.consignmentId.length > 0)
        this.consignmentId = item.consignmentId;
      if (item.file && item.file.length > 0) this.chosenFile = item.file;
      this.deleteItemConfirm();
    },
    deleteItemConfirm() {
      this.dataAndFiles.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    okDialogPressed() {
      this.logInDataReceived = false;
      this.dialogLogin = false;
      this.errorMessageLogin = "";
      this.showLogInWarning = false;

      //put data to null
    },
  },
};
</script>
<style scoped>

 .v-btn{
    border-radius:28px!important;
 } 
 .v-btn:hover{
   background-color:#D6AF00 ;
 }
</style>
